import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonLinkView from "../generic/Button/ButtonLinkView";
import ButtonLink from "../generic/Button/ButtonLink";
import { makeAutoObservable } from "mobx";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 80%;
  }
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const ButtonInputsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
`;

const buttonLink1 = ButtonLink.createDefultButtonLink();
makeAutoObservable(buttonLink1);

const TextButtonSliderContentMedia = ({
  id,
  editData,
  handleMediaData,
  handleClose,
}) => {
  const theme = React.useContext(ThemeContext);

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const intl = useIntl();

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const [data, setData] = React.useState(
    editData
      ? {
        ...editData,
      }
      : {
        title: "",
        subtitle: "",
        textColor: "",
      },
  );

  const submit = () => {
    handleMediaData(id, {
      ...data,
      positionHData: data?.positionHData?.value,
      positionVData: data?.positionVData?.value,
      button1: buttonLink1.getButtonData(),
    });

    handleClose();
  };

  React.useEffect(() => {
    setEditorLoaded(true);
    buttonLink1.clearData();
    if (editData?.button1) {
      buttonLink1.setButtonData(editData.button1);
      setEditorLoaded(true);
    }
  }, []);

  const [error, setError] = React.useState({
    hasError: false,
    fieldsError: {},
  });

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setData({
      ...data,
      [name]: checked,
    });
  };
  return (
    <Root>
      <Title>
        {editData ? translate("edit content") : translate("add content")}
      </Title>
      <InputData width="100%">
        <StyledTextfield
          id="title"
          name="title"
          onChange={onChange}
          value={data?.title}
          label={intl.formatMessage({ id: "title" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
      </InputData>
      <InputData width="100%">
        <StyledTextfield
          id="subtitle"
          name="subtitle"
          onChange={onChange}
          value={data?.subtitle}
          label={intl.formatMessage({ id: "subtitle" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
      </InputData>
      <InputData width="100%">
        <StyledTextfield
          id="textColor"
          name="textColor"
          onChange={onChange}
          value={data?.textColor}
          label={intl.formatMessage({ id: "textColor" })}
          variant="outlined"
          width="100%"
          margin="normal"
          type="color"
        />
      </InputData>
      <ButtonInputsContainer>
        <ButtonLinkView buttonLink={buttonLink1} error={error} />
      </ButtonInputsContainer>

      <Button
        onClick={submit}
        bg={theme.palette.accent}
        color={theme.palette.base}
      >
        {theme.icons.check}
      </Button>
    </Root>
  );
};

export default TextButtonSliderContentMedia;
