import React from "react";
import styled from "styled-components";
import {TextField} from "@mui/material";
import InputGallery from "../inputGallery";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {alertConstants} from "../../store/reducers/messageBar/alertConstants";
import {alertMessage} from "../../store/action-creators";
import {Sponsor} from "../section/Sponsor";
import SectionClient from "../section/SectionClient";
import {AssertionsFailed} from "../../creationAssertion/AssertionsFailed";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Text from "../generic/Text/Text";
import {makeAutoObservable} from "mobx";
import TextView from "../generic/Text/TextView";
import ButtonLinkView from "../generic/Button/ButtonLinkView";
import ButtonLink from "../generic/Button/ButtonLink";

const Field = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;
`;

const GalleriesContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  @media (min-width: 1024px) {
    width: ${(props) => (
            props.width ? props.width : "100%")};
  }
`

export const Actions = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
`;

let textFormatter = Text.createDefaultText()
makeAutoObservable(textFormatter)
let buttonLink = ButtonLink.createDefultButtonLink()
makeAutoObservable(buttonLink)

const sponsorView = React.forwardRef(({ id, userId, editData }, ref) => {
    const intl = useIntl();
    const sectionClient = new SectionClient()

    const navigate = useNavigate();
    const [hasText, setHasText] = React.useState(true);
    const [hasButton, setHasButton] = React.useState(true);
    const galleryRef = React.useRef();
    const [error, setError] = React.useState({
        hasError: false,
        fieldsError: {}
    })

    const loadImages = () => {
        galleryRef.current.handleSubmit();
    }

    React.useEffect(()=>{
        textFormatter.clearData()
        buttonLink.clearData()
        if(editData) {
            setHasText(editData.title.hasText)
            setHasButton(editData.button.hasButton)
            textFormatter.setTitleEditData(editData)
            buttonLink.setEditData(editData)
        }
    },[])

    React.useImperativeHandle(ref, () => ({
        handleAction() {
            loadImages();
        },
    }));

    const submit = async (medias)=> {
        try {
            const sponsor = new Sponsor(hasText, hasButton, textFormatter, buttonLink, userId, medias);

            await sectionClient.updateSection(id, sponsor);

            alertMessage(intl.formatMessage({ id: "section saved" }), alertConstants.INFO);
            navigate(-1);
        }
        catch (error) {
            if (error instanceof AssertionsFailed) {
                setError({
                    hasError: true,
                    fieldsError: error.failedAssertions
                })
            }
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        }
    }

    const parseImages = (toParse) => {
        return toParse.map(elem => {
            return {
                id: elem.id,
                url: elem.img,
                alt: elem.alt,
                order: elem.order,
                data: elem.data
            }
        })
    }

    return (
        <Root>
            <h3>Configuración Titulo</h3>

            <InputContainer width="10%">
                <FormControlLabel control={
                    <Checkbox
                        defaultChecked
                        name="outstanding"
                        checked={hasText}
                        onChange={() => {setHasText(!hasText);}}
                    />
                } label="Mostrar Título" />
            </InputContainer>

            {
                hasText &&
                <TextView
                    error={error}
                    textFormatter={textFormatter}
                />
            }

            <h3>Botón</h3>
            <InputContainer width="10%">
                <FormControlLabel control={
                    <Checkbox
                        defaultChecked
                        checked={hasButton}
                        onChange={() => {setHasButton(!hasButton);}}
                    />
                } label="Mostrar Boton" />
            </InputContainer>

            {
                hasButton &&
                <ButtonLinkView
                    buttonLink={buttonLink}
                    error={error}
                />
            }

            <GalleriesContainer>
                <InputGallery
                    editData={
                        editData
                            ? parseImages(editData.images)
                            : null
                    }
                    ref={galleryRef}
                    forceColumn={false}
                    handleAction={submit}
                    withContent={false}
                />
            </GalleriesContainer>
        </Root>
    )
})

export default sponsorView
