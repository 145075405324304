export default class ButtonLink {
    static ERROR_INVALID_BUTTON_TEXT = "Error invalid button text";
    static ERROR_INVALID_LINK_BUTTON = "Error invalid link button";
    static ERROR_INVALID_BUTTON_TYPE = "Error invalid button type";

    static TYPE = [
        {
            label: "Primario",
            value: "btn-primary",
        },
        {
            label: "Primario Outlined",
            value: "btn-primary-outlined",
        },
        {
            label: "Secundario",
            value: "btn-secondary",
        },
        {
            label: "Secundario Outlined",
            value: "btn-secondary-outlined",
        },
    ];

    static createDefultButtonLink() {
        return new this("", "", null, false);
    }

    constructor(text, link, type, newTabLink) {
        this._text = text;
        this._link = link;
        this._type = type;
        this._newTabLink = newTabLink;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }

    get link() {
        return this._link;
    }

    set link(value) {
        this._link = value;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }

    get newTabLink() {
        return this._newTabLink;
    }

    set newTabLink(value) {
        this._newTabLink = value;
    }

    hasText() {
        return this.text !== null && this.text.trim().length > 0;
    }

    hasLink() {
        return this.link !== null && this.link.trim().length > 0;
    }

    hasType() {
        return (
            this.type !== null &&
            this.type.label !== undefined &&
            this.type.label !== null
        );
    }

    hasNewTabLink() {
        return this.newTabLink !== null;
    }

    setEditData(editData) {
        if (editData !== null && editData.text !== null) {
            if (editData.button.text !== undefined) this.text = editData.button.text;

            if (editData.button.link !== undefined) this.link = editData.button.link;

            if (editData.button.type !== undefined)
                this.type = ButtonLink.TYPE.find(
                    (type) => type.value === editData.button.type,
                );

            if (editData.button.newTabLink !== undefined)
                this.newTabLink = editData.button.newTabLink;
        }
    }

    setButtonData(button) {
        if (button !== null && button !== undefined) {
            if (button.text !== undefined) this.text = button.text;

            if (button.link !== undefined) this.link = button.link;

            if (button.type !== undefined)
                this.type = ButtonLink.TYPE.find((type) => type.value === button.type);

            if (button.newTabLink !== undefined) this.newTabLink = button.newTabLink;
        }
    }

    getButtonData() {
        return {
            text: this.text,
            link: this.link,
            type: this.type?.value,
            newTabLink: this.newTabLink,
        };
    }

    clearData() {
        this.text = "";
        this.link = "";
        this.type = null;
        this.newTabLink = false;
    }
}
