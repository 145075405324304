export default class Text {
    static ERROR_INVALID_TITLE_TEXT = 'Error invalid title text'
    static ERROR_INVALID_TITLE_COLOR = 'Error invalid title color';
    static ERROR_INVALID_TITLE_ALIGNMENT = 'Error invalid title alignment';
    static BLACK_COLOR = '#000000';

    _text;
    _alignment;
    _color;

    static ALIGNMENT = [
        {
            label: 'Izquierda',
            value: 'left'
        },
        {
            label: 'Centro',
            value: 'center'
        },
        {
            label: 'Derecha',
            value: 'right'
        }
    ]

    static createDefaultText(){
        return new this('', null, Text.BLACK_COLOR)
    }

    setTitleEditData(editData){
        if(editData !== null && editData.title !== null) {
            if (editData.title.text !== undefined)
                this.text = editData.title.text

            if (editData.title.alignment !== undefined)
                this.alignment = Text.ALIGNMENT.find(alignment => alignment.value === editData.title.alignment)

            if (editData.title.color !== undefined)
                this.color = editData.title.color
        }
    }

    constructor(text, alignment, color) {
        this._text = text;
        this._alignment = alignment;
        this._color = color;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }

    get alignment() {
        return this._alignment;
    }

    set alignment(value) {
        this._alignment = value;
    }

    get color() {
        return this._color;
    }

    set color(value) {
        this._color = value;
    }

    clearData() {
        this.text = ''
        this.alignment = ''
        this.color = Text.BLACK_COLOR
    }

    hasAlignment(){
        console.log(this.alignment)
        return this.alignment !== undefined && this.alignment !== null && this.alignment !== ''
    }

    hasColor(){
        return this.color !== undefined && this.color !== null && this.color.trim().length > 0
    }

    hasText(){
        return this.text !== undefined && this.text !== null && this.text.trim().length > 0
    }
}
