import { useIntl } from "react-intl";
import SelectInput from "../../inputs/SelectInput";
import React from "react";
import { InputContainer } from "../../templatesSections/sponsorView";
import styled from "styled-components";
import { TextField } from "@mui/material";
import { ErrorMessage } from "../../courses/ErrorMessage";
import ButtonLink from "./ButtonLink";
import { observer } from "mobx-react-lite";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const Field = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const ButtonLinkView = observer((props) => {
  const intl = useIntl();

  const buttonType = ButtonLink.TYPE.map((type) => {
    return {
      label: intl.formatMessage({ id: type.value }),
      value: type.value,
    };
  });

  const errorMessage = (errorMessage) => {
    if (props.error === undefined || props.error.hasError === false)
      return null;

    const errorDescriptions = props.error.fieldsError.map((assertion) =>
      assertion.getDescription()
    );
    return (
      <ErrorMessage
        condition={() =>
          props.error.hasError &&
          props.error.fieldsError.some((error) =>
            error.isIdentifiedAs(errorMessage)
          )
        }
        messageId={errorDescriptions[0]}
      />
    );
  };

  return (
    <Container>
      <InputContainer width="20%">
        <Field
          id="buttonText"
          name="buttonText"
          onChange={(event) => {
            props.buttonLink.text = event.target.value;
          }}
          value={props.buttonLink.text}
          label={intl.formatMessage({ id: "text button" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          type="text"
        />
        {errorMessage(ButtonLink.ERROR_INVALID_BUTTON_TEXT)}
      </InputContainer>
      <InputContainer width="20%">
        <Field
          id="buttonLink"
          name="buttonLink"
          onChange={(event) => {
            props.buttonLink.link = event.target.value;
          }}
          value={props.buttonLink.link}
          label={intl.formatMessage({ id: "button link" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          type="text"
        />
        {errorMessage(ButtonLink.ERROR_INVALID_LINK_BUTTON)}
      </InputContainer>
      <InputContainer width="20%">
        <SelectInput
          data={buttonType}
          value={props.buttonLink.type}
          fieldlabel="label"
          fieldvalue="value"
          label={intl.formatMessage({ id: "type button" })}
          name="status"
          onChange={(name, value) => {
            props.buttonLink.type = value;
          }}
          width="100%"
          margin="normal"
          size="small"
        />
        {errorMessage(ButtonLink.ERROR_INVALID_BUTTON_TYPE)}
      </InputContainer>
      <FormControlLabel
        control={
          <Checkbox
            name="newTabLink"
            checked={props.buttonLink.newTabLink}
            onChange={(name, checked) => {
              props.buttonLink.newTabLink = checked;
            }}
            inputProps={{ "aria-label": "newTabLink" }}
          />
        }
        label={intl.formatMessage({ id: "newTab" })}
      />
    </Container>
  );
});

export default ButtonLinkView;
